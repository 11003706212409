
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[lang]/healthcare",
      function () {
        return require("private-next-pages/[lang]/healthcare/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[lang]/healthcare"])
      });
    }
  