import styled from 'styled-components';

type GridProps = {
  cardWidth?: number | string;
};

const CSSGrid = styled.div<GridProps>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${p => (p.cardWidth ? `min(100%, ${p.cardWidth}px)` : '250px')}, 1fr)
  );
  grid-gap: 20px;
`;

export const ThreeColumnGrid = styled(CSSGrid)`
  @media (min-width: ${props => props.theme.viewports.tablet}px) {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
`;

export default CSSGrid;
