import React, { useContext, useState } from 'react';
import Styled from 'styled-components';
import { Trans } from '@lingui/react';
import { t } from '@lingui/macro';
import Hero from '@ecosystems/landing_pages/hero/Hero';
import {
  TextWrapper,
  TextWrapperInner,
  Title,
  SubTitle,
  Paragraph,
  VideoWrapper,
  Disclaimer,
  ActionsWrapper,
} from '@ecosystems/landing_pages/hero/components';
import { Container, Box } from '@components/layout/Grid';
import SectionHeader from '@components/SectionHeader';
import InstructorMeta from '@components/InstructorMeta';
import { TestimonialStatic } from '@components/texts';
import { ThreeColumnGrid } from '@components/CSSGrid';
import SimpleAccordionList from '@organisms/SimpleAccordionList';
import MetaTags from '@components/MetaTags';
import { Video } from '@gql/generated';
import perksBanner from '@ecosystems/yearly/PerksBanner';
import ThumbnailWithRatio from '@components/ThumbnailWithRatio';
import Button from '@components/buttons';
import Trailer from '@organisms/video-player/Trailer';
import { UserContext } from '@lib/contexts/UserProvider';
import LanguageContext from '@lib/contexts/languageContext';

const HeroSection = Styled(Hero)`
  b {
    color: #253238;
    white-space: nowrap;
  }

  ${TextWrapper} {
    padding-right: 8%;
    margin: 0 auto;

    ${TextWrapperInner} {
      max-width: 720px;
    }
    
    ${Title} {
      font-size: 34px;
      line-height: 1.2;
      margin-bottom: 0px;
    }

    ${SubTitle} {
      font-size: 20px;
      line-height: 1.4;
      margin-top: 24px;
    }

    ${Disclaimer} {
      font-size: 13px;
      margin-top: 10px;
    }

    @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
      ${Title} {
        font-size: 24px;
      }
      margin-top: 20px;
    }

    @media (max-width: ${props => `${props.theme.viewports.mobile}px`}) {
      padding-right: 0;
    }
  }

  ${ThumbnailWithRatio.toString()}{
    width: 100%;
  }

  ${VideoWrapper} {
    display: flex;
    align-items: center;
  }
`;

const PerksBanner = Styled(perksBanner)`
  background-color: #d1e5e3;
  margin: 0;

  ${TextWrapper}{
    padding-right: 0;
    margin-left: 0;
  }

  h2, p, span, ul {
    color: #1C343E !important;
  }
  
  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      min-width: 240px;
      width: 50%;
    }
  }

  a {
    width: 100%;
    color: white;
    background-color: #87C0C1;
    
    &:hover {
      background-color: #87C0C1;
      opacity: .9;
    }
  }

  @media(min-width: ${({ theme }) => theme.viewports.tablet}px){
    a {
      width: auto;
    }
  }
`;

const ThirdSection = Styled(Hero)`
  max-width: 860px;
  width: 100%;
  margin: 0 auto;

  ${TextWrapper} {
    width: 100%;
    padding: 30px 20px;
    margin: 0 auto;
    
    ${Title} {
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 20px;
    }

    ${SubTitle} {
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 20px;
    }

    h2 {
      margin-bottom: 0;
    }

    p, ul, li {
      font-size: 18px;
    }

    .Hero--Description {
      font-size: 16px;
      line-height: 1.4;
      font-weight: 300;
      margin-bottom: 20px;

   
    }
  }

  img {
    max-width: 100%;
    height: 400px;
    object-fit: contain;
    object-position: left;
  }
`;

const FourthSection = Styled(Hero)`
  width: 100%;
  overflow: visible;
  padding: 30px 20px;

  ${TextWrapper} {
    padding: 20px 0 0;
    margin: 0 auto;

    ${TextWrapperInner} {
      max-width: 720px;
    }
    
    ${Title} {
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 20px;
    }

    ${SubTitle} {
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 20px;
    }

    ${Paragraph} {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.4;
    }

    ${ActionsWrapper} {
      a {
        width: 100%;
        background-color: #87C0C1;
        
        &:hover {
          background-color: #87C0C1;
          opacity: .9;
        }
      }
    }
  }

  @media(min-width: ${({ theme }) => theme.viewports.tablet}px){
    padding-top: 0;
    
    ${TextWrapper} {
      padding: 50px 40px 50px 0;
      
      ${ActionsWrapper}{
        a {
        width: auto;
      }
      }
    }

  }
`;

const FAQContainer = Styled(Container)`
  box-sizing: border-box;

  .SectionHeader {
    font-size: 18px;
    font-weight: 500;
  }
`;

const FAQAccordion = Styled(SimpleAccordionList)`
  max-width: 840px;
`;

const heroVideo = {
  id: '2015b3b2-5a07-46c0-948f-ad5c90c5c2b1',
  identifier: '2h3z',
  title: 'Yearly-2-30sek 1920x1080.mp4',
  thumbnailUrl:
    'https://yogobe.23video.com/64968575/78631845/c6d769ee57449306d8a3a67f7fcb8093/large/embed/thumbnail.jpg',
  hls:
    'https://yogobe.23video.com/64968575/78631845/c6d769ee57449306d8a3a67f7fcb8093/video_hls/playlist.m3u8',
  player: {
    assets: [
      {
        src:
          'https://yogobe.23video.com/64968575/78631845/c6d769ee57449306d8a3a67f7fcb8093/video_mobile_high/yearly-2-30sek-1920x1080mp4-5-video.mp4',
        resolution: 180,
        size: 1477524,
      },
      {
        src:
          'https://yogobe.23video.com/64968575/78631845/c6d769ee57449306d8a3a67f7fcb8093/video_medium/yearly-2-30sek-1920x1080mp4-5-video.mp4',
        resolution: 360,
        size: 3115064,
      },
      {
        src:
          'https://yogobe.23video.com/64968575/78631845/c6d769ee57449306d8a3a67f7fcb8093/video_hd/yearly-2-30sek-1920x1080mp4-5-video.mp4',
        resolution: 720,
        size: 5625516,
      },
      {
        src:
          'https://yogobe.23video.com/64968575/78631845/c6d769ee57449306d8a3a67f7fcb8093/video_1080p/yearly-2-30sek-1920x1080mp4-5-video.mp4',
        resolution: 1080,
        size: 11913703,
      },
    ],
  },
} as Video;

const testimonials = {
  title: 'Så här säger utvalda experter om Yogobe Health & Care:',
  testimonials: [
    {
      name: 'Mona Drar, leg. psykolog:',
      shortDescription: '',
      testimonial:
        'Att tillgängligt kunna möta patienten på dennes villkor i vardagen med kvalitativ digital hjälp till självhjälp gör skillnad på riktigt.',
      thumbnail: '/static/healthcare/Testiomial_Mona_Drar.jpeg',
      squareImage: '/static/healthcare/Testiomial_Mona_Drar.jpeg',
    },
    {
      name: 'Nathalie Helm, leg. läkare.',
      shortDescription: '',
      testimonial:
        'Yogobes digitala program är lättillgängliga, utbildande och motiverade, och utgör ett fantastiskt verktyg för att stötta kvinnor som lider av bland annat stress och utmattning. Jag kan varmt rekommendera programmen hos Yogobe Health & Care.',
      thumbnail: '/static/healthcare/Nathalie_8.jpg',
      squareImage: '/static/healthcare/Nathalie_8.jpg',
    },
    {
      name: 'Jovanna Stolt, fysioterapeut:',
      shortDescription: '',
      testimonial:
        'Att kunna erbjuda ett digitalt alternativ för patienter som fått fysisk aktivitet på recept ökar tillgängligheten och följsamheten till förskrivningen. På Yogobe finns inspirerande tränings- och yogaklasser för individen att utforska i sitt eget tempo och på den nivå som passar just för dagen.',
      thumbnail: '/static/healthcare/Testimonial_Jovanna_Stolt_3.jpeg',
      squareImage: '/static/healthcare/Testimonial_Jovanna_Stolt_3.jpeg',
    },
  ],
};

const HeathCarePage = () => {
  const [readMore, setReadMore] = useState(false);
  const lang = useContext(LanguageContext);
  const [currentUser] = useContext(UserContext);

  return (
    <>
      <MetaTags
        title={data.meta.title}
        description={data.meta.desc}
        thumbnailUrl={data.meta.img}
      />
      <div>
        <Container flexDirection="column" px={[25, 20, 20]} py={30}>
          <HeroSection
            title={data.hero.title}
            subtitle={data.hero.desc}
            actions={
              !currentUser?.isPremium && currentUser?.trial
                ? [
                    <Button
                      key="0"
                      appearance="blue"
                      href={`/${lang}/get-started/trial`}
                      rounded
                    >
                      <Trans id="metadata.start_trial_btn" />
                    </Button>,
                  ]
                : []
            }
            video={
              data.hero['video'] ? (
                <Trailer
                  {...{
                    video: heroVideo,
                    thumbnailImage: heroVideo?.thumbnailUrl,
                    isLimited: false,
                    autoPlay: false,
                    videoSources: heroVideo?.player?.assets,
                    elementID: 'trailer_video',
                  }}
                />
              ) : null
            }
            image={data.hero.img}
            disclaimer={data.hero.disclaimer}
            reverseColumns
          />
        </Container>
      </div>
      <PerksBanner
        title={data.blueBanner.title}
        image="/static/healthcare/healthcare-digital.png"
        bullets={data.blueBanner.bullets}
        actions={[
          <Button
            key="0"
            appearance="default-white"
            href="/se/healthcare/programs"
          >
            {data.blueBanner.actions[0]}
          </Button>,
        ]}
      />
      <Container id="more-info">
        <ThirdSection
          title={data.main.title}
          subtitle={data.main.desc}
          description={
            <>
              {data.main.primary}
              {readMore ? (
                <>
                  {data.main.secondary}{' '}
                  <Box mb="20px">
                    <img src={data.main.img} />
                  </Box>
                </>
              ) : null}
            </>
          }
          actions={[
            {
              name: (
                <Trans
                  id={
                    readMore
                      ? t`pausa-smart.read-less`
                      : t`pausa-smart.read-more`
                  }
                />
              ),
              theme: 'outline',
              onClick() {
                setReadMore(!readMore);
              },
            },
          ]}
        />
      </Container>
      <Container style={{ backgroundColor: '#d1e5e3;' }} fullWidth>
        <Container>
          <FourthSection
            image={data.subscriptionSegment.img}
            title={data.subscriptionSegment.title}
            subtitle={data.subscriptionSegment.desc}
            description={
              <Paragraph>{data.subscriptionSegment.subtitle}</Paragraph>
            }
            disclaimer={data.subscriptionSegment.disclamer}
            actions={[
              {
                name: data.subscriptionSegment.actions[0],
                theme: 'stone',
                href: '/se/c/far/b/far',
              },
            ]}
            reverseColumns
          />
        </Container>
      </Container>
      {testimonials ? (
        <div style={{ backgroundColor: '#F5F5F5' }}>
          <Container flexDirection="column" my={30} px={[15, 20, 20]}>
            <Box mb={20}>
              <SectionHeader title={testimonials.title} />
            </Box>
            <ThreeColumnGrid>
              {testimonials.testimonials.map((testimonial, i, arr) => (
                <Box key={testimonial.name} mb={arr.length > i + 1 ? 30 : 20}>
                  <InstructorMeta
                    isClickable={false}
                    instructor={{
                      thumbnailUrl: testimonial.thumbnail,
                      name: testimonial.name,
                      shortDescription: testimonial.shortDescription,
                    }}
                  />
                  <TestimonialStatic>
                    {testimonial.testimonial}
                  </TestimonialStatic>
                </Box>
              ))}
            </ThreeColumnGrid>
          </Container>
        </div>
      ) : null}
      <FAQContainer flexDirection="column" my={30} px={[15, 25, 25]}>
        <Box mb={[25]} mx="auto">
          <SectionHeader title={data.faq.title} />
          <FAQAccordion list={data.faq.items} />
          <Box mt={[25]}>
            <Disclaimer>{data.faq.disclamer}</Disclaimer>
          </Box>
        </Box>
      </FAQContainer>
      {/* <PricingSection /> */}
    </>
  );
};

export default HeathCarePage;

const data = {
  banner:
    'Erbjudandet är tidsbegränsat och avslutas senast vid midnatt på söndagen den 12 november.',
  meta: {
    title: 'Yogobe Health & Care',
    img: '/static/healthcare/Header_image_B2H_landingpage.jpg',
    desc:
      'Digital prevention, egenvårdsprogram och komplementärt stöd till vårdinsatser inom mental hälsa och kvinnohälsa med fokus inom stress, utmattning, nedstämdhet, depression, sömnbesvär, oro, långvarig smärta, klimakteriet m.m. samt FaR – Fysisk aktivitet på recept. Vi samarbetar med vårdgivare, försäkringsbolag och arbetsgivare.',
  },
  hero: {
    title: 'Yogobe Health & Care',
    desc: (
      <>
        För att komplettera vårdkedjan och stötta vårdgivare, arbetsgivare och
        individer inom prevention, promotion och som stöd till behandling
        erbjuder Yogobe digitala verktyg i samråd med ledande experter.
      </>
    ),
    actions: [],
    disclaimer: 'Tillgängligt, jämlikt och anpassat efter individens behov.',
    video: null,
    img: '/static/healthcare/Header_image_B2H_landingpage.jpg',
  },
  blueBanner: {
    title: 'Vi möter individ- och patientbehov vid:',
    bullets: [
      { icon: 'ygb-icon-checkmark', text: 'Stress' },
      { icon: 'ygb-icon-checkmark', text: 'Utmattning' },
      { icon: 'ygb-icon-checkmark', text: 'Nedstämdhet' },
      { icon: 'ygb-icon-checkmark', text: 'Depression' },
      { icon: 'ygb-icon-checkmark', text: 'Sömnbesvär' },
      { icon: 'ygb-icon-checkmark', text: 'Oro' },
      { icon: 'ygb-icon-checkmark', text: 'Långvarig smärta' },
      {
        icon: 'ygb-icon-checkmark',
        text: 'Behov av FaR – Fysisk aktivitet på recept',
      },
      { icon: 'ygb-icon-checkmark', text: 'Klimakteriet' },
      { icon: 'ygb-icon-checkmark', text: 'Graviditet' },
    ],
    actions: ['Ta del av våra program'],
  },
  main: {
    title: '',
    desc: '',
    primary: (
      <>
        <h2>
          <strong>
            Digitala och digifysiska insatser tillsammans med Yogobe
          </strong>
        </h2>
        <p style={{ color: '#3c3c3c' }}>
          Tillsammans med vårdgivare, försäkringsbolag och arbetsgivare stöttar
          vi individer, medarbetare och patienter med kroppsbaserade metoder och
          hälsofrämjande aktiviteter i egenskap av förebyggande insatser, som
          sekundärprevention samt under och efter behandling. &nbsp;
          <a
            href="https://www.mynewsdesk.com/se/yogobe-se/pressreleases/storsatsning-paa-psykisk-haelsa-nu-lanseras-yogobe-health-och-care-3238129"
            target="_blank"
          >
            Läs vår pressrelease här!
          </a>
        </p>
        <p>
          <strong>Varf&ouml;r Yogobe Health &amp; Care?</strong>
        </p>
        <ul>
          <li>Tidsbesparande f&ouml;r v&aring;rdgivare och patient</li>
          <li>Kostnadseffektivt&nbsp;</li>
          <li>
            M&ouml;jligg&ouml;r f&ouml;r fler interventioner innan, under och
            efter behandling
          </li>
          <li>God och n&auml;ra v&aring;rd p&aring; patientens villkor</li>
          <li>
            H&ouml;g tillg&auml;nglighet och flexibilitet f&ouml;r patienten som
            kan delta utifr&aring;n egna f&ouml;ruts&auml;ttningar
          </li>
          <li>Kan leda till &ouml;kad f&ouml;ljsamhet</li>
          <li>
            Egenmonitorering av behandling, som skapar f&ouml;ruts&auml;ttningar
            till att patienten blir mer aktiv i sin rehabiliteringsprocess
          </li>
          <li>
            Digitala dimensioner som del i behandling frig&ouml;r resurser och
            m&ouml;jligg&ouml;r att v&aring;rdpersonal kan disponera sin tid
            f&ouml;r optimal nytta
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <strong>Patientfl&ouml;de med v&aring;ra digitala program</strong>
        </p>
        <p>
          Våra digital program passar som en preventiv insats eller som del i
          behandling, men även inför behandlingsstart, och efter
          behandlingsinsats som sekundärprevention – allt för att möta
          individens behov. Här följer ett exempel på ett patientflöde för våra
          program och komplementära digitala insatser :
        </p>
        <ul>
          <li>
            <strong>H&auml;lsobed&ouml;mning </strong>&ndash; Inledande
            h&auml;lsobed&ouml;mning utf&ouml;rd av legitimerad
            v&aring;rdpersonal, s&aring;som sjuksk&ouml;terska, fysioterapeut,
            l&auml;kare eller psykolog.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Insats</strong> &ndash; Utifr&aring;n bed&ouml;mning
            initieras en plan anpassad efter patientens behov och digitala
            st&ouml;d/program f&ouml;rskrivs.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Uppf&ouml;ljning</strong> &ndash; Regelbunden
            uppf&ouml;ljning av legitimerad v&aring;rdpersonal som kontinuerligt
            f&ouml;ljer patientens m&aring;ende och utv&auml;rderar resultaten.
          </li>
        </ul>
        <p style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
          <img
            src="/static/healthcare/Elin_B2H_page.jpg"
            style={{
              height: '160px',
              marginRight: '20px',
              marginBottom: '20px',
            }}
          />
          <span style={{ flex: 1 }}>
            Kontakta oss för att möta just era behov av stöd:
            <br />
            <br />
            Elin Karnstedt – Projektledare för Yogobe Health & Care &nbsp;
            <br />
            <a href="mailto:elin@yogobe.com" target="_blank" rel="noopener">
              elin@yogobe.com
            </a>
          </span>
        </p>
        <p>
          <strong>Forskning &amp; evidens</strong>
        </p>
        <p>
          Yogobe arbetar med ledande experter inom friskv&aring;rd, forskning
          och sjukv&aring;rd. Programmen &auml;r framtagna med bas i evidens och
          forskning med syfte att kunna erbjuda b&auml;sta m&ouml;jliga digitala
          egenv&aring;rdsprogram samt st&ouml;d till behandling. Nedan kan du
          l&auml;sa mer om n&aring;gra av v&aring;ra program.
        </p>
      </>
    ),
    secondary: (
      <>
        <p>
          <strong>YOMI-programmen&nbsp;</strong>
        </p>
        <p>
          Leds av legitimerade psykologer och erfarna yogal&auml;rare. H&auml;r
          kombineras teoretisk och psykologisk kunskap med praktiska
          mindfulness&ouml;vningar och yogasekvenser. Den psykologiska grunden
          bygger p&aring; KBT, Acceptance and Commitment Therapy och
          Compassion-fokuserad Terapi.&nbsp;
        </p>
        <p>
          Psykoedukationen som anv&auml;nds inom YOMI bygger p&aring; forskning
          och KBT-behandling inom de n&auml;mnda tillst&aring;nden (exempelvis
          beteendeaktivering f&ouml;r depression). Dessa program har i sin
          helhet unders&ouml;kts i ett antal studier, alla randomiserade,
          kontrollerade experiment, och &auml;r under p&aring;g&aring;ende
          fortsatt utv&auml;rdering i samarbete med Lunds universitet. Tre
          publikationer finns i dagsl&auml;get, i tidskrifterna Anxiety, Stress
          &amp; Coping, PLOS och Mindfulness. Studierna visar p&aring; goda
          effekter av programmet: &ldquo;YOMI vid stress &amp; oro&rdquo;.&nbsp;
        </p>
        <p>
          Vidare finns opublicerade resultat f&ouml;r tre studier, som i
          dagsl&auml;get &auml;r under bearbetning. Den senaste studien, 2020,
          genomf&ouml;rdes p&aring; YOMI vid utmattning eller sm&auml;rta i
          onlineversion, och visade en tydlig f&ouml;rb&auml;ttring f&ouml;r
          gruppen med utmattning. Dessa resultat &auml;r &auml;n s&aring;
          l&auml;nge opublicerade, men lovande.
        </p>
        <p>
          <strong>Traumaanpassad yoga&nbsp;</strong>
        </p>
        <p>
          <span style={{ color: '#212529' }}>
            Programmet &auml;r validerat och framtaget av Josefin Wikstr&ouml;m,
            licensierad yogaterapeut som specialiserar sig inom mental
            oh&auml;lsa och PTSD. I programmet medverkar &auml;ven Eleonora
            Ramsby Herrera, yogal&auml;rare med fokus p&aring; traumaanpassad
            yoga och meditation. L&auml;s mer om{' '}
          </span>
          <a
            href="https://yogobe.com/se/yoga/stilar/trauma-anpassad-yoga"
            target="_blank"
            rel="noopener"
          >
            <span style={{ color: '#0d6efd' }}>
              Traumaanpassad yoga h&auml;r
            </span>
          </a>
          <span style={{ color: '#212529' }}>
            . Vid Hartford Hospital forskas det p&aring; hur bland annat yoga
            kan fungera som medicin. F&ouml;r mer information, titta g&auml;rna
            p&aring;{' '}
          </span>
          <a
            href="https://www.youtube.com/watch?v=MJUwnElCM0c&amp;ab_channel=yoga4cancer%28y4c%29"
            target="_blank"
            rel="noopener"
          >
            <span style={{ color: '#0d6efd' }}>denna korta video</span>
          </a>
          <span style={{ color: '#212529' }}>
            {' '}
            fr&aring;n Green Island Films.
          </span>
        </p>
        <p>
          <strong>Stressprogrammet</strong>
        </p>
        <p>
          Programmet &auml;r framtaget och validerat av Mona Drar, legitimerad
          psykolog. Mona har arbetat som legitimerad psykolog i drygt 15
          &aring;r, och har bland annat jobbat inom BUP ( barn- och
          ungdomspsykiatrin) och har l&aring;ng erfarenhet av att arbeta med
          ledarskapsutveckling och inom f&ouml;retagsh&auml;lsov&aring;rden.
          Mona &auml;r KBT-psykolog och influerad av bland annat mindfulness och
          self-compassion i sitt arbete.
        </p>
      </>
    ),
    actions: [],
    img: '/static/healthcare/b2h.landing.page.jpg',
  },
  subscriptionSegment: {
    title: 'Yogobe och Fysisk aktivitet på recept (FaR)',
    desc: (
      <>
        Yogobe grundades 2013 med en vision om att alla människor ska ha
        tillgång till verktyg och förutsättningar för god fysisk och psykisk
        hälsa. Sedan starten har Yogobe vuxit till att bli Nordens ledande och
        mest omfattande digitala tjänst inom yoga, mindfulness, återhämtning,
        rörelseträning och stresshantering.
        <br />
        <br />
        Yogobe är certifierad som godkänd aktivitetsarrangör för FaR av
        Akademiskt Primärvårdscentrum (APC). Detta som ett led i vår vision att
        tillgängliggöra högkvalitativa digitala stöd och verktyg för träning på
        individens villkor.
      </>
    ),
    subtitle: '',
    disclamer: '',
    actions: ['Läs mer om FaR och ta del av vårt innehåll!'],
    img: '/static/healthcare/FAR.B2H.jpg',
  },
  faq: {
    title: 'Vanliga frågor och funderingar',
    items: [
      {
        title: 'Vem kan delta i ett program?',
        body:
          'Du som har blivit ordinerad eller tilldelad access via en vårdgivare, arbetsgivare eller ett försäkringsbolag. Dessa program är del av Yogobe Health & Care och ligger utanför den prenumerationstjänst som erbjuds till privatpersoner.',
      },
      {
        title: 'Hur kommer jag åt mitt program?',
        body: (
          <>
            <p>
              Efter att du har anm&auml;lt dig och f&aring;tt access till ett
              program s&aring; har du smidig tillg&aring;ng till det via Yogobes
              app under &ldquo;Mitt bibliotek&rdquo; eller l&auml;ngst upp
              p&aring; din{' '}
              <a
                href="https://yogobe.com/se/me/dashboard"
                target="_blank"
                rel="noopener"
              >
                &ouml;versiktssida
              </a>{' '}
              n&auml;r du &auml;r inloggad p&aring; Yogobe.com
            </p>
          </>
        ),
      },
      {
        title: 'Kan företag köpa enskilda program?',
        body: (
          <>
            <p>
              Sj&auml;lvklart! S&aring;v&auml;l arbetsgivare som
              f&ouml;rs&auml;krings- och v&aring;rdbolag kan k&ouml;pa in Yogobe
              och v&aring;ra program samt digitala interventioner, som st&ouml;d
              och komplement till befintliga insatser till f&ouml;rm&aring;n
              f&ouml;r individ, medarbetare och patient. Maila oss p&aring;{' '}
              <span style={{ color: '#56b9e5' }}>business@yogobe.com</span>{' '}
              s&aring; ber&auml;ttar vi mer.
            </p>
          </>
        ),
      },
      {
        title: 'Måste jag betala?',
        body: (
          <>
            Nej. Om du kommer till Yogobe Health & Care med en accesskod som du
            har fått från en patientförening, vårdgivare, arbetsgivare eller
            försäkringsbolag så betalar du personligen ingenting för åtkomst
            till det specifika Yogobe-program eller material som du ordinerats.
          </>
        ),
      },
      {
        title: 'Hur använder jag mitt FaR-recept på Yogobe?',
        body: (
          <>
            Om du har ett FaR-recept som du vill anv&auml;nda p&aring; Yogobe
            s&aring; ber vi dig skicka en kopia (en fotograferad bild g&aring;r
            bra) p&aring; ditt FaR-recept till{' '}
            <a href="mailto:info@yogobe.com" target="_blank" rel="noopener">
              info@yogobe.com
            </a>
            . D&auml;refter f&aring;r du instruktioner kring hur du enkelt
            kommer ig&aring;ng med Yogobes FaR-specifika utbud.
          </>
        ),
      },
    ],
    disclamer: (
      <>
        Vid ytterligare frågor, funderingar eller tankar tveka inte att skriva
        till oss på <a href="mailto:info@yogobe.com">info@yogobe.com</a>.
      </>
    ),
    actions: ['Ta del av erbjudandet'],
  },
};
